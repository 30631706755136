import React, { useState } from "react";
import hero from "../../../assets/img/jorie_ai.png";
import { FaRegArrowAltCircleUp, FaRegArrowAltCircleDown } from "react-icons/fa";

const Hero = () => {
  const [showFullParagraph, setShowFullParagraph] = useState(false);

  const toggleShowParagraph = () => {
    setShowFullParagraph(!showFullParagraph);
  };
  return (
    <div className=" duration-300 rounded-md shadow-lg \">
      <div
        className="container min-h-[620px] flex mx-auto mb-8 lg:mb-16"
        style={{ flexDirection: "column" }}
      >
        <div
          className="font-bold mt-10"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 class="mb-4 text-4xl md:text-5xl tracking-tight font-extrabold text-primary">
            Get To Know Jorie
          </h2>
          {/* <h1
            data-aos="fade-up"
            className="sm:text-5xl font-bold mt-5  dark:text-white"
            style={{ lineHeight: 1.2 }}
          >
            Get To Know Jorie
          </h1> */}
        </div>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 place-items-center container"
          style={{ padding: "0 3rem" }}
        >
          {/* Image section */}
          <div data-aos="zoom-in" className="order-1 sm:order-2 relative">
            <img
              src={hero}
              alt=""
              className="w-full sm:max-w-[400px] md:max-w-[600px] rounded-lg"
              style={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
            {/* <div
              data-aos="slide-right"
              className="absolute -bottom-5 -right-8 px-4 py-2 rounded-xl bg-white dark:bg-gray-900 dark:text-white shadow-md"
            >
              <p className="text-gray-500 text-sm ">⭐Projects</p>
              <h1 className="font-bold">
                600+ <span className="font-normal">Done</span>
              </h1>
            </div> */}
          </div>

          {/* Text section */}
          <div className="space-y-5 order-2 sm:order-1 xl:pr-20">
            <h1
              data-aos="fade-up"
              className="text-3xl sm:text-4xl font-semibold ml-10"
              style={{ lineHeight: 1.2 }}
            >
              Our Mission
            </h1>
            <p
              data-aos="fade-up"
              data-aos-delay="300"
              className="ml-5 text-1xl text-primary"
            >
              Jorie Healthcare is transforming the healthcare industry through
              advanced technology and innovation. As a healthcare service
              provider with a rich history of developing cutting-edge solutions,
              we've harnessed the power of artificial intelligence to improve
              patient outcomes and streamline operations. Our technology has
              been validated by industry giants, cementing our position as the
              dominant and prevailing provider of AI technology.
              {!showFullParagraph ? (
                <>
                  {" "}
                  {/* <button onClick={toggleShowParagraph} className="text-blue-500 hover:underline focus:outline-none">{' '} </button>*/}
                </>
              ) : (
                <>
                  {" "}
                  But our mission is about more than just providing the best
                  technology – it's about empowering healthcare providers and
                  allowing more money to be put back into their pockets. Our
                  solutions are designed to reduce costs and improve revenue, so
                  providers can invest more in their patients and their
                  business.
                  {/* <button onClick={toggleShowParagraph} className="text-blue-500 hover:underline focus:outline-none">Read Less</button>*/}
                </>
              )}
            </p>
            {!showFullParagraph ? (
              <FaRegArrowAltCircleDown onClick={toggleShowParagraph} fontSize={20} color="#5bc8ac" padding='6px' cursor='pointer' />
            ) : (
              <FaRegArrowAltCircleUp onClick={toggleShowParagraph} fontSize={20} color="#5bc8ac" padding='6px' cursor='pointer' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;