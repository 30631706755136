import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React, { useState } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import InformationModal from "./informationModal/informationModal";
import { getRevData } from "getRevData";

export default function Default(props) {
  const [open, setOpen] = useState(false)
  const { startContent, endContent, growth, name, value, name2, value2, name3, value3 } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const info = 'But while the all-electric market is slowing, sales are forecast to keep rising. Cox Automotive expects EVs to comprise 10% of the United States vehicle market by the end of the year, up from 7.6% last year — when domestic sales hit a record 1.2 million — and'
  const infoData = getRevData(name)
  return (
    <Card py='13px' className='stat-card'>
      <Flex
        my='auto'
        h='100%'
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}>
        {startContent}
        <Stat my='auto' ms={startContent ? "18px" : "0px"}>
          <StatLabel
            lineHeight='40%'
            color={textColorSecondary}
            marginBottom='-8px'
            fontWeight='extrabold'
            display='flex'
            justifyContent='space-between'
            fontSize={{ base: "12px", sm: "12px", md: "14px", lg: "14px", xl: "14px" }}
          >
            {name}
            <HiOutlineInformationCircle onClick={() => setOpen(true)} />
          </StatLabel>
          <StatNumber
            className="top-stat"
            color={textColor}
            marginBottom='5px'
            fontSize={{ base: "14px", sm: "14px", md: "16px", lg: "16px", xl: "16px" }}>
            <InformationModal open={open} onClose={() => setOpen(false)} info={infoData} name={name} />
            {value}
          </StatNumber>
          {/* <div className="extra-stats" > */}
            <StatLabel
              lineHeight='40%'
              color={textColorSecondary}
              fontSize={{ base: "10px", sm: "12px", md: "14px", lg: "14px", xl: "14px" }}>
              {name2}
            </StatLabel>
            <StatNumber
              className="top-stat"
              color={textColor}
              marginBottom='5px'
              fontSize={{ base: "12px", sm: "14px", md: "16px", lg: "16px", xl: "16px" }}>
              {value2}
            </StatNumber>
            <StatLabel
              lineHeight='50%'
              color={textColorSecondary}
              fontSize={{ base: "10px", sm: "12px", md: "14px", lg: "14px", xl: "14px" }}>
              {name3}
            </StatLabel>
            <StatNumber
              className="top-stat"
              color={textColor}
              fontSize={{ base: "12px", sm: "14px", md: "16px", lg: "16px", xl: "16px" }}>
              {value3}
            </StatNumber>
          {/* </div> */}
          {growth ? (
            <Flex align='center'>
              <Text color='green.500' fontSize={{ base: "10px", sm: "12px", md: "14px", lg: "16px", xl: "18px" }} fontWeight='700' me='5px'>
                {growth}
              </Text>
              <Text color='secondaryGray.600' fontSize={{ base: "10px", sm: "12px", md: "14px", lg: "16px", xl: "18px" }} fontWeight='400'>
                since last month
              </Text>
            </Flex>
          ) : null}
        </Stat>
        <Flex ms='auto' w='max-content'>
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}