import React, { useContext } from "react";
// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import { Select } from "@chakra-ui/react";
import { UserContext } from "contexts/UserContext";
import { ImFacebook } from "react-icons/im";
import { BsInstagram } from "react-icons/bs";
import { FaTwitter, FaLinkedinIn, FaYoutube } from "react-icons/fa6";

// FUNCTIONS

function SidebarContent(props) {
  const { clients, activeClient, selectClient } = useContext(UserContext);
  const { routes } = props;

  const handleChangeClient = (e) => {
    selectClient(e.target.value);
  };
  console.log("activeClient", activeClient);
  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      px="16px"
      borderRadius="30px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px" height='90%' display='flex' alignContent='center'>
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
        <Box style={{ display: clients.length === 1 ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          {clients.length > 0 && (
            <Select
              defaultValue={activeClient}
              onChange={handleChangeClient}
              placeholder="Select option"
              fontSize="14px" // Set font size to 14px
            >
              {clients.map((client) => (
                <option key={client.name} value={client.name}>{client.name}</option>
              ))}
            </Select>
          )}
        </Box>
        <Box height='100%' width='100%' display='flex' alignItems='flex-end' justifyContent='center'>
          <Box marginBottom='1.5rem' display='flex' justifyContent='center' alignItems='center' flexDirection='column' width='45%'>
            <h4 className='font-light text-base' style={{ fontSize: '14px' }}>Find Jorie AI on</h4>
            <div style={{ width: '70%', borderBottom: '1.5px solid #5bc8ac', marginBottom: '16px' }}></div>
            <Box display='flex' justifyContent='space-between' width='100%'>
              <a href="https://www.facebook.com/JorieAI/" about="_blank" style={{ cursor: 'pointer' }}><ImFacebook size={16} /></a>
              <a href="https://www.instagram.com/jorie.ai/" about="_blank" style={{ cursor: 'pointer' }}><BsInstagram size={16} /></a>
              <a href="https://twitter.com/JoriePartners" about="_blank" style={{ cursor: 'pointer' }}><FaTwitter size={16} /></a>
              <a href="https://www.linkedin.com/company/jorieai/" about="_blank" style={{ cursor: 'pointer' }}><FaLinkedinIn size={16} /></a>
              <a href="https://www.youtube.com/@JorieAI" about="_blank" style={{ cursor: 'pointer' }}><FaYoutube size={16} /></a>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;