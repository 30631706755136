import axios from "axios";

// const BASE_URL = 'http://localhost:8000/api/'
const BASE_URL = 'https://clientportal.jorie.ai/api/'
const interceptor = async (error) => {
      var refresh = false;
      if (error.response.status === 401 && !refresh) {
        refresh = true;
        console.log(localStorage.getItem("refresh_token"));
  
        try {
          const response = await axios.post(
            BASE_URL + "token/refresh/",
            {
              refresh: localStorage.getItem("refresh_token"),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );
          console.log(`response`, response)
          if (response.status === 200) {
            // Set Authorization header for this specific request
            error.config.headers["Authorization"] = `Bearer ${response.data["access"]}`;
            localStorage.setItem("access_token", response.data.access);
            localStorage.setItem("refresh_token", response.data.refresh);
            return axios(error.config);
          }
        } catch (refreshError) {
          console.log("Error refreshing token:", refreshError);
          window.location.reload()
        }
      }
      refresh = false;
      return Promise.reject(error);
    }

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

const refreshToken = () => api.post('/token/refresh/', {refresh: localStorage.getItem('refresh_token')});


const authenticatedApi = () =>{ 
    const api = axios.create({
    baseURL: BASE_URL,// replace with your API base URL
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })

    api.interceptors.response.use(
            (resp) => resp,
            interceptor
    )
    return api
}
const getClients = () =>  authenticatedApi().get('clients/')

const getDashboard= (client='') => authenticatedApi().get(`dashboard/?${client?`client=${client}`: ''}`)

const getTableauData= (client='') => authenticatedApi().get(`tableau-data/?${client?`client=${client}`: ''}`)

export default {getClients, getDashboard,getTableauData}