import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";


const InformationModal = ({ open, onClose, children, info, name }) => {
  return (
    // backdrop
    <div
      onClick={onClose}
      className={`
            fixed inset-0 flex justify-center items-center transition-colors
            min-h-[260px] min-w-[260px] bg-white
            ${open ? "visible bg-black/20" : "invisible"}
          `}
      style={{ zIndex: "10000000" }}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        style={{width: '660px', height: '500px', overflow: 'hidden'}}
        className={`
              rounded-xl shadow p-6 transition-all
              min-h-[260px] min-w-[260px]
              ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
      >
        {children}
        <span style={{display: 'flex', justifyContent: 'flex-end', color: '#888888', cursor: 'pointer'}}><IoMdCloseCircleOutline onClick={onClose} /></span>
          <div className="container min-h-[260px] min-w-[260px] flex">
            
            <div
              className="grid grid-cols-1 sm:grid-cols-1 place-items-center container"
              style={{ padding: "0 3rem", width:'100%'}}
            >
              <div className="space-y-5 order-2 sm:order-1" style={{display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'flex-start', width: '100%'}}>
                <h1
                  data-aos="fade-up"
                  className="text-3xl sm:text-2xl font-semibold flex"
                  style={{ lineHeight: 1.2, textAlign: 'center' }}
                >
                    {name}
                </h1>
                <div style={{display: 'flex', fontSize: '14px'}}>
                   <span> Definition: <p className="text-gray-500 dark:text-gray-400 font-semibold">{info[0]}</p></span>
                </div>
                <div style={{display: 'flex', fontSize: '14px'}}>
                    <span>
                        Target: <p className="text-gray-500 dark:text-gray-400">{info[1]}</p>
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default InformationModal;