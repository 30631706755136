import React from "react";
import hero from "../../../assets/img/about/hero.jpg";
import image_1 from '../../../assets/img/about/gallery/image_1.webp'
import image_2 from '../../../assets/img/about/gallery/image_2.webp'
import image_3 from '../../../assets/img/about/gallery/image_3.webp'
import image_4 from '../../../assets/img/about/gallery/image_4.webp'
import image_5 from '../../../assets/img/about/gallery/image_5.webp'
import image_6 from '../../../assets/img/about/gallery/image_6.webp'

const Gallery = () => {
  const data = [
    {
      imgelink: image_1,
    },
    {
      imgelink: image_2,
    },
    {
      imgelink: image_3,
    },
    {
      imgelink: image_4,
    },
    {
      imgelink: image_5,
    },
    {
      imgelink: image_6,
    }
  ];
  const [active, setActive] = React.useState(
    image_1,
  );
  return(
    <div className="duration-300 rounded-md shadow-lg mt-5 mb-5">
      <div className="container min-h-[620px] flex mb-10">
        <div
          className="grid grid-cols-1 sm:grid-cols-2 gap-6 place-items-center"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: '10px'
          }}
        >
          {/* Image section */}
          <div
            style={{
              width: "100%",
              overflow: 'hidden',
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            <div className="grid gap-4 md:w-[75%] sm:w-[100%]">
              <div>
                <img
                  className="h-auto w-full max-w-full rounded-lg object-cover object-center sm:h-[360px] md:h-[480px] overflow-hidden"
                  src={active}
                  alt=""
                />
              </div>
              <div className="grid grid-cols-5 gap-2">
                {data.map(({ imgelink }, index) => (
                  <div key={index}>
                    <img
                      onClick={() => setActive(imgelink)}
                      src={imgelink}
                      className="h-20 sm:w-[200px] md:w-[400px] cursor-pointer rounded-lg object-cover object-center
                      overflow-hidden"
                      alt="gallery-image"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
