/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  MdArrowUpward,
  MdArrowDownward
} from "react-icons/md";
import ArBuckets from "views/admin/default/components/ArBuckets";
import { formatClientData, formatNumber } from "client_utility";
import '../../../interceptors/axios'
import PayerMix from "./components/PayerMix";
import NetCollection from "./components/NetCollection";
import ClaimVolumes from "./components/ClaimVolumes";
import '../../../interceptors/axios'
import Loading from "../loading/Loading";
import RevenueOutcome from "./components/RevenueOutcomes";
import api from "api";
import { UserContext } from "contexts/UserContext";
import ClaimResultTrends from "./components/ClaimResultsTrends";
import { motion } from "framer-motion";

const modifyObject = (str) => {
  try {
    // Replace single quotes with double quotes and replace "None" with string representation of None
    const fixedStr = str.replace(/'None'/g, '"None"').replace(/None/g, '"None"').replace(/'/g, '"');

    // Parse JSON string
    const parsedData = fixedStr ? JSON.parse(fixedStr) : {}; // If fixedStr is falsy, default to empty object

    // Function to remove key-value pairs where the value is "None"
    const removeNoneValues = (obj) => {
      const filteredObj = {};
      for (const [key, value] of Object.entries(obj)) {
        if (value !== '"None"') {
          if (typeof value === 'object' && value !== null) {
            filteredObj[key] = removeNoneValues(value); // Recursively process nested objects
          } else {
            filteredObj[key] = value; // Keep other values as is
          }
        }
      }
      return filteredObj;
    };

    // Apply function to remove "None" values
    const filteredData = removeNoneValues(parsedData);

    return filteredData;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    // Handle parsing errors (optional)
    return {}; // Or throw a more specific error
  }
};

function processArray(arr) {
  // Check if the length of the array is less than three
  if (arr.length < 2) {
    // Append "string one" and "string two" to the appropriate positions
    arr.unshift("string one");
    arr.splice(1, 0, "string two");
    return arr;
  } else {
    // If there are three or more values, return the original array
    return arr;
  }
}

function processRow2Arrays(arr) {
  // Check if the length of the array is less than two
  if (arr.length < 2) {
    // Append "string one" to make it at least two values
    arr.unshift("string one");
  }

  return arr;
}

function processArray3(arr) {
  // Check if the length of the array is less than 3
  if (arr.length < 3) {
    // Append two objects to make it at least three values
    arr.unshift({ "0": "Positive" });
    arr.unshift({ "0": "Current Month" });
  }

  return arr;
}

export default function UserReports() {
  const [loading, setLoading] = useState(true)
  const [tableauData, setTableauData] = useState([]);
  const [comparisonData, setComparisonData] = useState([])
  const [claimVolumeData, setClaimVolumeData] = useState([])
  const [payerMixData, setPayerMixData] = useState([])
  const [netCollectionData, setNetCollectionData] = useState([])
  const [revenueOutcomeData, setRevenueOutcomeData] = useState([])
  const [requesting, setRequesting] = useState(false)
  const { activeClient } = useContext(UserContext)
  const [scrollProgress, setScrollProgress] = useState(0);
  const shouldScrollToTop = useRef(false);

  const url = "https://clientportal.jorie.ai/api/tableau-data/";
useEffect(() => {
    setLoading(true)
    console.log('client changed')
    const accessToken = localStorage.getItem("access_token");
    if (accessToken === null) {
      window.location.href = "/login";
    } else {
      (async () => {
        try {
          if (requesting) {
            console.log('requesting...')
            return;
          }

          setRequesting(true)
          const { data } = await api.getTableauData(activeClient)
          const chartData = data.chart_data_results

          const comparisonDataObj = JSON.parse(chartData[0])
          const revenueOutcomeObj = JSON.parse(chartData[1])
          const netCollectionsObj = JSON.parse(chartData[2])
          const claimVolumeDataObj = JSON.parse(chartData[3])
          const payerMixDataObj = JSON.parse(chartData[4])


          setTableauData(data.client_data);
          setComparisonData(comparisonDataObj)
          setClaimVolumeData(claimVolumeDataObj)
          setPayerMixData(payerMixDataObj)
          setNetCollectionData(netCollectionsObj)
          setRevenueOutcomeData(revenueOutcomeObj)
          setLoading(false)
          setRequesting(false)
          console.log('requesting should be false')
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [activeClient]);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  if (loading) return <div style={{ 'height': '100vh', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
    <Loading />
  </div>
  return (
    
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
          gap='10px'
          mb='10px'>

          {
            tableauData.map((tabItem, i) => {
              const statName1 = Object.keys(modifyObject(tabItem[0]))[1];
              const statValue1 = processArray(Object.values(modifyObject(tabItem[0])))[2]['0']
              const statName2 = Object.keys(modifyObject(tabItem[1]))[0];
              const statValue2 = processRow2Arrays(Object.values(modifyObject(tabItem[1])))[1]['0'];
              const statName3 = Object.keys(modifyObject(tabItem[2]))[0];
              const statValue3 = processArray(Object.values(modifyObject(tabItem[2])))[2]['0'];
              const intVal = processArray3(Object.values(modifyObject(tabItem[0])))[1]['0'];
              return (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: i * 0.3 }}
                >
                  <MiniStatistics
                    keys={i}
                    startContent={
                      <IconBox
                        w='36px'
                        h='36px'
                        bg={boxBg}
                        icon={
                          <Icon w='24px' h='24px' as={intVal === 'Negative' ? MdArrowDownward : MdArrowUpward} color={intVal === 'Negative' ? '#f70025' : '#5C8F22'} />
                        }
                      />
                    }
                    name={formatClientData(statName1)}
                    value={formatNumber(statValue1)}
                    name2={formatClientData(statName2)}
                    value2={formatNumber(statValue2)}
                    name3={formatClientData(statName3)}
                    value3={formatNumber(statValue3)}
                  />
                </motion.div>
              )
            }
            )
          }
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
          {comparisonData && <ArBuckets chartData={comparisonData} />}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
          {/* Conditionally render MonthlyCost component */}
          {claimVolumeData && <ClaimVolumes chartData={claimVolumeData} />}
          {/* Conditionally render PayerMix component */}
          {payerMixData && <PayerMix chartData={payerMixData} />}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
          {/* Conditionally render MonthlyCost component */}
          {activeClient !== 'Ortho One' ?
            netCollectionData && <NetCollection chartData={netCollectionData} /> :
            netCollectionData && <ClaimResultTrends chartData={netCollectionData} />}
          {revenueOutcomeData && <RevenueOutcome chartData={revenueOutcomeData} />}
        </SimpleGrid>
      </Box>
  );
}