import { Box } from "@chakra-ui/react";
import { MdOutlineLocalPhone, MdOutlineEmail } from "react-icons/md";
import React, { useContext, useEffect, useState } from "react";
import "../../../interceptors/axios";
import Card from "components/card/Card";
import { teamMembers } from "../team/teamData";
import api from "api";
import { UserContext } from "contexts/UserContext";
import {getTeamMember} from '../team/clientTeamMembers'

export default function MeetTheTeam() {
  const [userData, setUserData] = useState([]);
  const [teamName, setTeamName] = useState('')
  const [teamData, setTeamData] = useState([])

  const { activeClient } = useContext(UserContext);
  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken === null) {
      window.location.href = "/login";
    } else {
      (async () => {
        try {
          const { data } = await api.getDashboard(activeClient);
          console.log('DATA', data)
          setUserData(data);
          setTeamName(data.client_name)
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, []);

  // const team = getTeamMember(teamName) || [];
  console.log('DATA DATA: ', userData)
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card align="center" direction="column" w="100%">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
            {/* dark:text-white */}
            <h2 class="mb-4 text-4xl md:text-5xl tracking-tight font-extrabold text-primary">
              Meet The Jorie Team
            </h2>
            <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">
              At Jorie, our company motto is 'Go JT,' which stands for 'Go Jorie
              Team.' It embodies our core philosophy of unity and collaboration.
              We believe in working together tirelessly to achieve our common
              goals, always moving forward as a cohesive team. With 'Go JT,' we
              are committed to delivering excellence through teamwork and
              unwavering determination.
            </p>
          </div>
          <div
            class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6"
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            <h2
              class="mb-4 text-3xl tracking-tight font-semibold"
              style={{ margin: "0px auto" }}
            >
              The Exectutive Team
            </h2>
          </div>
          <div class="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3">
            {teamMembers.map((member) => (
              <Card key={member.id}>
                <div class="text-center">
                  <img
                    class="mx-auto mb-4 w-36 h-36 rounded-full"
                    src={member.image}
                    alt={member.name}
                    style={{backgroundPosition: "center", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', objectFit: 'cover'}}
                  />
                  <h3 class="mb-1 text-2xl font-semibold tracking-tight">
                    <a href="#">{member.name}</a>
                  </h3>
                  <p className="text-gray-500 dark:text-gray-400">{member.position}</p>
                  <ul class="flex justify-center mt-4 space-x-4">
                    <li>
                      <a
                        href={`mailto:${member.email}`}
                        class="text-[#39569c] hover:text-gray-900 dark:hover:text-white"
                      >
                        <MdOutlineEmail color="#2988bc" size="24px" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`tel:${member.phone}`}
                        class="text-[#00acee] hover:text-gray-900 dark:hover:text-white"
                      >
                        <MdOutlineLocalPhone color="#ed8c72" size="24px" />
                      </a>
                    </li>
                  </ul>
                </div>
              </Card>
            ))}
          </div>
          <div
            class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6"
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            <h2
              class="mb-4 text-3xl tracking-tight font-semibold"
              style={{ margin: "0px auto" }}
            >
              Meet The {activeClient || userData.client_name} Team
            </h2>
          </div>
          <div class="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3">
            <Card key={userData.client_name}>
              <div class="text-center">
                <img
                  class="mx-auto mb-4 w-36 h-36 rounded-full"
                  src={`https://clientportal.jorie.ai${userData.client_primary_image}`}
                  alt="customer service specialist"
                  style={{backgroundPosition: "center", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', objectFit: 'cover'}}
                />
                <h3 class="mb-1 text-2xl font-bold tracking-tight member-name">
                  <a href="#">{userData.client_primary_contact}</a>
                </h3>
                <p className="text-gray-500 dark:text-gray-400">
                  Customer Service Specialist
                </p>
                <ul class="flex justify-center mt-4 space-x-4">
                  <li>
                    <a
                      href={`mailto:${userData.client_primary_email}`}
                      class="text-[#39569c] hover:text-gray-900 dark:hover:text-white"
                    >
                      <MdOutlineEmail color="#2988bc" size="24px" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`tel:${userData.client_primary_phone}`}
                      class="text-[#00acee] hover:text-gray-900 dark:hover:text-white"
                    >
                      <MdOutlineLocalPhone color="#ed8c72" size="24px" />
                    </a>
                  </li>
                </ul>
              </div>
            </Card>
            <Card key={userData.client_name}>
              <div class="text-center">
                <img
                  class="mx-auto mb-4 w-36 h-36 rounded-full"
                  src={`https://clientportal.jorie.ai/api${userData.client_primary_image}`}
                  alt="assistant customer service specialist"
                  style={{backgroundPosition: "center", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', objectFit: 'cover'}}
                />
                <h3 class="mb-1 text-2xl font-bold tracking-tight member-name">
                  <a href="#">{userData.client_secondary_contact}</a>
                </h3>
                <p className="text-gray-500 dark:text-gray-400">
                Asst. Customer Service Specialist
                </p>
                <ul class="flex justify-center mt-4 space-x-4">
                  <li>
                    <a
                      href={`mailto:${userData.client_secondary_email}`}
                      class="text-[#39569c] hover:text-gray-900 dark:hover:text-white"
                    >
                      <MdOutlineEmail color="#2988bc" size="24px" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`tel:${userData.client_secondary_phone}`}
                      class="text-[#00acee] hover:text-gray-900 dark:hover:text-white"
                    >
                      <MdOutlineLocalPhone color="#ed8c72" size="24px" />
                    </a>
                  </li>
                </ul>
              </div>
            </Card>
          </div>
        </div>
        <div className="flex" style={{ flexDirection: "column" }}>
          <Card>
            <section className="">
              <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
                  <h2 className="text-3xl font-extrabold">
                    Have Questions? Contact Us
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Don't hesitate to say hi, we'd love to hear from you.
                  </p>
                </div>
                <div className="mt-16 lg:mt-20">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="rounded-lg overflow-hidden">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5944.536533720665!2d-87.94595942348704!3d41.84405226754458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4c6d62eaad6f%3A0xf1d40ce058567f71!2s1000%20Jorie%20Blvd%2C%20Oak%20Brook%2C%20IL%2060523!5e0!3m2!1sen!2sus!4v1714130073498!5m2!1sen!2sus"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                    <div>
                      <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                        <div className="px-6 py-4">
                          <h3 className="text-lg font-semibold">
                            Our Address
                          </h3>
                          <p className="mt-1 text-gray-600">
                            1000 Jorie Blvd #370, Oak Brook, IL 60523
                          </p>
                        </div>
                        <div className="border-t border-gray-200 px-6 py-4">
                          <h3 className="text-lg font-medium">
                            Contact
                          </h3>
                          <p className="mt-1 text-gray-600">

                            Email: <a href="mailto:info@joriehc.com">info@joriehc.com</a>
                          </p>
                          <p className="mt-1 text-gray-600">
                            Phone: <a href="tel:(888)522-0022">1 (888) 522-0022</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Card>
        </div>
      </Card>
    </Box>
  );
}