export const getRevData = (name) => {
    if(name === 'Net Collected Revenue'){
        const data = 'Data Stories'
        const target = 'coming soon'

        return [data, target]
    }
    if(name === 'Monthly Net Revenue'){
        const data = 'Data Stories'
        const target = 'coming soon'
        return [data, target]
    }
    if(name === 'Clean Claim Rate'){
        const data = 'Data Stories'
        const target = 'coming soon'
        return [data, target]
    }
    if(name === 'Total Charges'){
        const data = 'Data Stories'
        const target = 'coming soon'
        return [data, target]
    }
    // if(name === 'Monthly Net Revenue'){
    //     data = 'The term Net Revenue has diverse definitions within the industry but is best defined as: The value of the hospitals dollar, less contractuals, less bad debt.mDifferent methods are used to determine both the value of the Active AR and related %s that are applied toGross Revenue to determine its Net Revenue.'
    //     return data
    // }
    // if(name === 'Monthly Net Revenue'){
    //     data = 'The term Net Revenue has diverse definitions within the industry but is best defined as: The value of the hospitals dollar, less contractuals, less bad debt.mDifferent methods are used to determine both the value of the Active AR and related %s that are applied toGross Revenue to determine its Net Revenue.'
    //     return data
    // }
    // if(name === 'Monthly Net Revenue'){
    //     data = 'The term Net Revenue has diverse definitions within the industry but is best defined as: The value of the hospitals dollar, less contractuals, less bad debt.mDifferent methods are used to determine both the value of the Active AR and related %s that are applied toGross Revenue to determine its Net Revenue.'
    //     return data
    // }
    // if(name === 'Monthly Net Revenue'){
    //     data = 'The term Net Revenue has diverse definitions within the industry but is best defined as: The value of the hospitals dollar, less contractuals, less bad debt.mDifferent methods are used to determine both the value of the Active AR and related %s that are applied toGross Revenue to determine its Net Revenue.'
    //     return data
    // }
    // if(name === 'Monthly Net Revenue'){
    //     data = 'The term Net Revenue has diverse definitions within the industry but is best defined as: The value of the hospitals dollar, less contractuals, less bad debt.mDifferent methods are used to determine both the value of the Active AR and related %s that are applied toGross Revenue to determine its Net Revenue.'
    //     return data
    // }
    else{
        const data = 'Data Stories'
        const target = 'Coming soon'
        return [data, target]
    }
}