import React from "react";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { GrGroup } from "react-icons/gr";
import { FaArrowUp } from "react-icons/fa6";

const skillsData = [
  {
    name: "Hospital & Health Systems",
    icon: <MdOutlineOndemandVideo className="text-4xl text-primary" />,
    link: "#",
    description:
      "Our mission is to empower these institutions with innovative tools and technologies, enabling them to deliver the highest standard of care to their patients while optimizing operational efficiency.",
    aosDelay: "0",
  },
  {
    name: "Physician & Medical Groups",
    icon: <GrGroup className="text-4xl text-primary" />,
    link: "#",
    description:
      "At Jorie, we are dedicated to providing cutting-edge solutions designed specifically for physical and medical groups within the healthcare industry. Our goal is to support these organizations in delivering superior patient care, streamlining their operations, and enhancing overall performance through our innovative solutions.",
    aosDelay: "300",
  },
  {
    name: "Health Services Companies",
    icon: <FaArrowUp className="text-4xl text-primary" />,
    link: "#",
    description:
      "At Jorie, we are committed to developing advanced solutions tailored to the unique needs of health services companies. Our mission is to empower these organizations to optimize their services, streamline operations, and improve patient outcomes through our innovative and customizable solutions.",
    aosDelay: "500",
  },
  //   {
  //     name: "Digital Marketing",
  //     icon: <SlNote className="text-4xl text-primary" />,
  //     link: "#",
  //     description:
  //       "ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod metus vel sem bibendum, a bibendum justo tempor. Sed vel lectus",
  //     aosDelay: "700",
  //   },
];
const Services = () => {
  return (
    <div className="dark:bg-[#ffffff] dark:text-gray duration-300 rounded-md shadow-lg">
      <div className="container min-h-[620px] flex">
        <span id="about"></span>
        <div className="bg-[%f1f1f1] dark:text-white py-12 sm:grid sm:place-items-center">
          <div className="container">
            {/* Header */}
            <div className="pb-12 text-center space-y-3">
              <h1
                data-aos="fade-up"
                className="text-3xl font-semibold sm:text-3xl"
              >
                Healthcare Organizations Jorie Builds Solutions For
              </h1>
              <p
                data-aos="fade-up"
                className="text-gray-600 dark:text-gray-400 text-sm"
              >
                Our Healthcare AI solutions serve several industries from hospitals and physician groups to payors and medical billing companies.
              </p>
            </div>

            {/* services cards */}
            <div class=" md:grid grid-cols-3 sm:grid-cols-1 xl:grid-cols-3 gap-4 justify-items-center content-center">
              {skillsData.map((skill) => (
                <div
                  key={skill.name}
                  data-aos="fade-up"
                  data-aos-delay={skill.aosDelay}
                  className="card space-y-3 sm:space-y-4 p-4"
                >
                  <div className="">{skill.icon}</div>
                  <h1 className="text-lg font-semibold">
                    {skill.name}
                  </h1>
                  <p className="text-gray-600 dark:text-gray-400">
                    {skill.description}
                  </p>
                </div>
              ))}
            </div>

            {/* button */}
            <div
              data-aos="fade-up"
              data-aos-delay="900"
              data-aos-offset="0"
              className="text-center mt-4 sm:mt-8"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
