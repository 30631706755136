import React from "react";
import WhyCard from "./WhyCard";
import Img1 from "../../../assets/img/about/image_1.webp";
import Img2 from "../../../assets/img/about/image_2.webp";
import Img3 from "../../../assets/img/about/image_3.webp";

const whyData = [
  {
    id: 1,
    image: Img1,
    title: "Independent & Hospital Based Physician Groups",
    description:
      "As consolidation efforts rise, independent physician groups face myriad challenges. These groups can benefit from improved operational and administrative efficiencies, positioning them for growth maintained independence.",
  },
  {
    id: 2,
    image: Img2,
    title: "Healthcare Insurance/Payors",
    description:
      "Enterprise Management, \nProcess Execution Velocity, \nBot Quality Percentage, \nBot Utilization ",
  },
  {
    id: 3,
    image: Img3,
    title: "Medical Billing Companies",
    description:
      "Enhancing clinical, operational and financial performance for the nation's leading hospitals/physicians and billing systems by providing implementation support backed by rigorous analytics to drive actionable and sustainable improvements across the entire revenue cycle continuum.",
  },
];

const Why = () => {
  return (
    <>
      <div className="d dark:text-white py-10 pb-14 rounded-md shadow-lg">
        <section data-aos="fade-up" className="container ">
          <h1 className="my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-semibold"> 
            Who We Serve
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1">
            {whyData.map((item) => (
              <WhyCard key={item.id} {...item} />
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default Why;